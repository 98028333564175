
import { Component, Vue } from "vue-property-decorator";
import { Dialog, Toast } from "vant";
import { locale } from "dayjs";
import UAgreement from "../../agreement.vue";
import zfagreement from "../../zf-agreement.vue";
import zfprivacy from "../../zf-privacy.vue";
import UPrivacy from "../../privacy.vue";
import UAuthorization from "../../authorization.vue";
import RightsAgreement from "../../rights-agreement.vue";
import RightsPrivacy from "../../rights-privacy.vue";
import globalConfig from "src/config/index";
const showRightsCode = globalConfig.showRightsCode;
import ermInfo from "../../ermInfo.vue";
import { UserService } from "src/services";

@Component({
  components: {
    UPrivacy,
    UAgreement,
    UAuthorization,
    RightsAgreement,
    RightsPrivacy,
    ermInfo,
    zfagreement,
    zfprivacy,
  },
})
export default class Privacy extends Vue {
  /**
   * 是否显示服务协议
   * @private
   * @returns boolean
   */
  private showAgreement: boolean = false;

  /**
   * 是否显示隐私协议
   * @private
   * @returns boolean
   */
  private showPrivacy: boolean = false;

  /**
   * 是否显示中福服务协议
   * @private
   * @returns boolean
   */
  private showZfAgreement: boolean = false;

  /**
   * 是否显示中福隐私协议
   * @private
   * @returns boolean
   */
  private showZfPrivacy: boolean = false;

  /**
   * 是否显示服务隐私协议
   * @private
   * @returns number
   */
  private showErmInfoModal: boolean = false;

  /* 业务员隐私协议关闭
   * @private
   * @returns void
   */
  private async onCloseErmInfoModal(): Promise<void> {
    this.showErmInfoModal = false;
  }

  /* 跳转中福服务协议
   * @private
   * @returns void
   */
  private onzfAgreement(): void {
    this.showZfAgreement = true;
  }

  /* 跳转中福隐私协议
   * @private
   * @returns void
   */
  private onzfPrivacy(): void {
    this.showZfPrivacy = true;
  }

  /* 关闭中福服务协议
   * @private
   * @returns void
   */
  private closeZfAgreement(): void {
    this.showZfAgreement = false;
  }

  /* 关闭中福隐私政策
   * @private
   * @returns void
   */
  private closeZfpPrivacy(): void {
    this.showZfPrivacy = false;
  }

  /**
   * 服务隐私协议id
   * @private
   * @returns number
   */
  private ermInfoId: any = null;

  /* 业务员隐私协议显示
   * @private
   * @returns void
   */
  private async onTermInfos(id): Promise<void> {
    this.ermInfoId = id;
    this.showErmInfoModal = true;
  }

  /**
   * 获取当前登入机构信息
   * @private
   * @returns void
   */
  private orgInfo: any = {};

  private created(): void {
    let code = localStorage.getItem("appid");
    // 获取机构信息
    this.getOrgInfo(code);
  }

  /**
   * 获取机构详情
   * @private
   * @returns void
   */
  private async getOrgInfo(code: string): Promise<void> {
    try {
      let { content: result } = await UserService.instance.getOrgInfo(code);
      this.orgInfo = result.data;
    } catch (err) {
      Toast(err);
    }
  }

  /**
   * 是否显示用户授权协议
   * @private
   * @returns boolean
   */
  private showAuthorization: boolean = false;

  /**
   * 是否显示权益服务协议
   * @private
   * @returns boolean
   */
  private showRightsAuthorization: boolean = false;

  /**
   * 是否显示权益隐私协议
   * @private
   * @returns boolean
   */
  private showRightsAgreement: boolean = false;

  /**
   * 是否显示权益隐私协议
   * @private
   * @returns boolean
   */
  private showRightsPrivacy: boolean = false;

  /**
   * 获取当前登入企业相关配置
   * @private
   * @returns void
   */
  protected get employerConfig(): any {
    let appid = localStorage.getItem("appid");

    let employerConfig = globalConfig.employerConfig[appid];

    return employerConfig;
  }

  /**
   * 获取当前登入信息
   * @private
   * @returns void
   */
  private get showRights(): any {
    return showRightsCode.includes(localStorage.getItem("appid"));
  }

  /**
   * 打开隐私协议
   * @private
   * @returns void
   */
  private onPrivacy(): void {
    if (this.showRights) {
      this.showRightsPrivacy = true;
    } else {
      this.showPrivacy = true;
    }
  }

  /**
   * 打开服务协议
   * @private
   * @returns void
   */
  private onAgreement(): void {
    if (this.showRights) {
      this.showRightsAgreement = true;
    } else {
      this.showAgreement = true;
    }
  }

  /**
   * 用户授权协议
   * @private
   * @returns void
   */
  private onAuthorization(): void {
    if (this.showRights) {
      this.showRightsAuthorization = true;
    } else {
      this.showAuthorization = true;
    }
  }

  /* 跳转携保隐私
   * @private
   * @returns void
   */
  private closeRightsPrivacy(): void {
    this.showRightsPrivacy = false;
  }

  /* 关闭携保权益协议
   * @private
   * @returns void
   */
  private closeRightsAgreement(): void {
    this.showRightsAgreement = false;
  }

  /* 关闭用户协议
   * @private
   * @returns void
   */
  private closeAgreement(): void {
    this.showAgreement = false;
  }

  /* 关闭用户授权协议
   * @private
   * @returns void
   */
  private closeAuthorization() {
    this.showAuthorization = false;
  }

  /* 关闭隐私协议
   * @private
   * @returns void
   */
  private closePrivacy(): void {
    this.showPrivacy = false;
  }
}
